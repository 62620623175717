import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import ThemeProvider from 'src/theme/ThemeProvider'
import AuthenticationWall from 'src/components/AuthenticationWall/AuthenticationWall'
import ErrorBoundary from 'src/components/ErrorBoundary'
import LoadingPlaceholder from 'src/components/LoadingPlaceholder'
import store from 'src/store'
import { LicenseInfo } from '@mui/x-license-pro';
require('dayjs/locale/en-sg')

LicenseInfo.setLicenseKey('4156ebd017c329b8ba81d0d84f66eefeTz0xMDc4NDEsRT0xNzcxMDI3MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))

function App() {

  return (

    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-sg">
        <CssBaseline/>
        <ErrorBoundary>
          <Provider store={store}>
            <AuthenticationWall>
              <Suspense fallback={<LoadingPlaceholder/>}>
                <AuthenticatedApp/>
              </Suspense>
            </AuthenticationWall>
          </Provider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App


