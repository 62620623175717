import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import Administrator from '../../model/Administrator'
import Branch from '../../model/Branch'
import Manager from '../../model/Manager'
import Merchant from '../../model/Merchant'
import {
  BranchesActions, rejectUpdateBranch,
  RejectUpdateBranchAction, resolveUpdateBranch,
  ResolveUpdateBranchAction,
  UpdateBranchAction,
} from '../actions/branches'
import {
  ChangePasswordAction,
  ProfileActions,
  rejectChangePassword, RejectChangePasswordAction,
  rejectFetchProfile,
  RejectFetchProfileAction,
  rejectUpdateProfile,
  RejectUpdateProfileAction,
  resolveChangePassword,
  ResolveChangePasswordAction,
  resolveFetchProfile,
  ResolveFetchProfileAction,
  resolveUpdateProfile,
  ResolveUpdateProfileAction,
  UpdateProfileAction,
} from '../actions/profile'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import * as actionTypes from '../constants/ActionTypes'

interface ProfileReducerState {
  administrator: Administrator | null
  isFetching: boolean
  error: Error | null
  successFlashMessage?: string
}

const initialState: ProfileReducerState = {
  administrator: null,
  isFetching:    false,
  error:         null,
}

export default function profile(state: ProfileReducerState = initialState, action: ProfileActions | BranchesActions): ProfileReducerState | Loop<ProfileReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          return loop(
            {
              ...state,
              isFetching: true,
            },
            Cmd.run(API.getProfile, {
              successActionCreator: resolveFetchProfile,
              failActionCreator:    rejectFetchProfile,
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProfileAction
          const { profileResponse } = payload
          const { administrator } = profileResponse

          return {
            ...state,
            isFetching: false,
            administrator,
          }
        }

        case false: {
          const { payload } = action as RejectFetchProfileAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PROFILE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateProfileAction

          return loop(
            {
              ...state,
              err:        null,
              isFetching: true,
            },
            Cmd.run(API.putAdministrator, {
              successActionCreator: resolveUpdateProfile,
              failActionCreator:    rejectUpdateProfile,
              args:                 [payload.name, payload.emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateProfileAction
          const { manager } = payload

          return loop({
              ...state,
              isFetching:          false,
              manager,
              successFlashMessage: 'Profile updated successfully!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateProfileAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.CHANGE_PASSWORD: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as ChangePasswordAction

          return loop(
            {
              ...state,
              err:        null,
              isFetching: true,
            },
            Cmd.run(API.postChangePassword, {
              successActionCreator: resolveChangePassword,
              failActionCreator:    rejectChangePassword,
              args:                 [payload.currentPassword, payload.newPassword],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveChangePasswordAction
          const { manager } = payload

          return loop({
              ...state,
              isFetching:          false,
              manager,
              successFlashMessage: 'Password changed successfully!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectChangePasswordAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: undefined,
      }
    }

    default:
      return Object.assign({}, state)
  }
}
